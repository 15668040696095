import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from "./modules/auth/pages/login/login.component";
import { NotFoundComponent } from "./core/pages/not-found/not-found.component";
import { InvoiceGeneratorComponent } from "./modules/erp/pages/invoice-generator/invoice-generator.component";


const routes: Routes = [
  {
    path: "",
    component: InvoiceGeneratorComponent
  },
  {
    path: "auth",
    component: InvoiceGeneratorComponent,
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => {
        return m.AuthModule
      }),
  },
  {
    path: "erp",
    component: InvoiceGeneratorComponent,
    loadChildren: () =>
      import("./modules/erp/erp.module").then((m) => m.ErpModule),
  },
  {
    path: "**",
    component: NotFoundComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
