<!DOCTYPE html>
<html>

<head>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.3/jquery.min.js"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
</head>

<body>

    <div style="display: flex;flex-direction: row;flex-flow: nowrap;">

        <div class="container">

            <div class="row" style="display: flex;flex-direction: row;">
         
                <div class="col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 col-xs-12 brandSection" style="float: left;width: 60%;">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 header">
                            <div class="col-md-3 col-sm-3 headerLeft">
                                <img style="max-width: 11vh;"
                                    src="https://www.monitorerp.com/media/6348/logo-monitor-white.png">
                            </div>
                            <div class="col-md-9 col-sm-9 headerRight">
                                <p>www.demoBuyerERP.com</p>
                                <p>support@NRP.com</p>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 content">
                            <h1>Invoice <strong># {{random_invoice_number}}</strong></h1>
                            <p>{{todayDate}}</p>
                            <span>Payment due by {{dueDate}}</span>
                        </div>
                        <div class="col-md-12 col-sm-12 panelPart">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 panelPart">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            Seller
                                        </div>
                                        <div class="panel-footer">
                                            <div class="row">
                                                <div class="col-md-8 col-sm-6 col-xs-6" style="width: 100%;">
                                                    <p>{{seller?.company_name}}</p>
                                                    <p>{{seller?.company_gstin}}</p>
                                                    <p>{{seller?.company_address}}</p>
                                                    <p>{{seller?.company_phone}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 panelPart">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            Buyer
                                        </div>
                                        <div class="panel-footer">
                                            <div class="row">
 
                                                <div class="col-md-8 col-sm-6 col-xs-6" style="width: 100%;">
                                                    <p>{{buyer?.company_name}}</p>
                                                    <p>{{buyer?.company_gstin}}</p>
                                                    <p>{{buyer?.company_address}}</p>
                                                    <p>{{buyer?.company_phone}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 tableSection">
                            <h1>ITEMS</h1>
                            <table class="table text-center">
                                <thead>
                                    <tr class="tableHead">
                                        <th style="width:30px;">Quantity</th>
                                        <th>Description</th>
                                        <th style="width:100px;">Unit Price</th>
                                        <th style="width:100px;text-align:center;">TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor='let row of data[shuffleValue]'>
                                        <td>{{row?.quantity}}</td>
                                        <td>{{row?.description}}</td>
                                        <td>{{row?.price}}</td>
                                        <td>Rs.{{(row.price - 0)*(row.quantity - 0) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-12 col-sm-12 lastSectionleft ">
                            <div class="row">
                                <div class="col-md-8 col-sm-6 Sectionleft">
                                    <p><i>Special Notes : </i><i>Test purpose only</i></p>
                                    <span><i>These data and representation are just for demo purpose.<br> It doesn't reflect any actual case.</i> </span>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="panel panel-default">
                                        <div class="panel-body lastPanel">
                                            AMOUNT DUE
                                        </div>
                                        <div class="panel-footer lastFooter">
                                            <div class="row">
                                                <div class="col-md-5 col-sm-6 col-xs-6 panelLastLeft">
                                                    <p>SUBTOTAL</p>
                                                    <p>CGST</p>
                                                    <p>SGST</p>
                                                    <p>TOTAL</p>
                                                </div>
                                                <div class="col-md-7 col-sm-6 col-xs-6 panelLastRight">
                                                    <p id="subtotal">{{totalSum}}</p>
                                                    <p id="cgst">9%</p>
                                                    <p id="sgst">9%</p>
                                                    <p><strong>Rs. {{totalSum * 0.18 + totalSum }}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="float:left; width: 20%;"></div>
                    </div>
                </div>
                <div style="float: right;width:20%">
                    <button class="button-29" role="button" (click)="shuffle()">Shuffle Purchase Item</button>

                    

                    <div class="btn-group" dropdown>
                        <button id="button-animated" dropdownToggle type="button" class="button-29"
                                aria-controls="dropdown-animated">
                          Select Seller* <span class="caret"></span>
                        </button>
                        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu"
                            role="menu" aria-labelledby="button-animated" > 
                            <div *ngFor='let data of entityList'>
                                <li role="menuitem"><a class="dropdown-item" (click)="sellectSeller({seller: data?._id})" >{{data?.company_name}}</a></li>
                            </div>
                        </ul>
                    </div>

                    <div class="btn-group" dropdown>
                        <button id="button-animated" dropdownToggle type="button" class="button-29"
                                aria-controls="dropdown-animated">
                          Select Buyer* <span class="caret"></span>
                        </button>
                        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu"
                            role="menu" aria-labelledby="button-animated">
                            <div *ngFor='let data of entityList'>
                                <li role="menuitem"><a class="dropdown-item" (click)="sellectBuyer({buyer: data?._id})">{{data?.company_name}}</a></li>
                            </div>
                        </ul>
                    </div>

                    <p class="button-30" style="height:10vh">Credit Limit: Rs.{{buyer?.company_credit_sanctioned}}<br><br>
                        Available Limit: Rs.{{buyer?.company_credit_available}}
                    </p>
                    <button class="button-29" role="button" (click)="Submit()">Submit Invoice</button>

                </div>
                
            </div>
        </div>
    </div>
</body>

</html>