import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from "../../services/api.service.service";

@Component({
  selector: 'app-invoice-generator',
  templateUrl: './invoice-generator.component.html',
  styleUrls: ['./invoice-generator.component.css']
})
export class InvoiceGeneratorComponent implements OnInit {

  constructor(
    private apiService: ApiServiceService,
  ){}
  random_invoice_number: any;
  seller: any;
  entityList: any;
  buyer: any;
  token: any;
  shuffleValue = 0;

  shuffle() {
    this.shuffleValue = Math.floor(Math.random() * 4)
    this.totalSum = this.data[this.shuffleValue].reduce((init, prev) => {  return Number(init) + Number(prev.quantity * prev.price) }, 0);
  }

  todayDate = new Date().toLocaleDateString("en-UK");

  dueDate = new Date(new Date().setDate(new Date().getDate() + 90)).toLocaleDateString("en-UK");

  data = [
    [{
      quantity: 2,
      description: "Bright Paint 20L emultion",
      price: 4110.00,
    },
    {
      quantity: 1,
      description: "BrightPaint 20kg distemper",
      price: 1330.00,
    },
    {
      quantity: 10,
      description: "BrightPaint wall putty",
      price: 850.00,
    },
    {
      quantity: 2,
      description: "Turpentine - 20lt",
      price: 800.00,
    }],
    [{
      quantity: 1,
      description: "Bright Paint 20L emultion",
      price: 4110.00,
    },
    {
      quantity: 5,
      description: "BrightPaint 20kg distemper",
      price: 1330.00,
    },
    {
      quantity: 20,
      description: "BrightPaint wall putty",
      price: 850.00,
    },
    {
      quantity: 3,
      description: "Turpentine - 20lt",
      price: 800.00,
    }],
    [{
      quantity: 8,
      description: "Bright Paint 20L emultion",
      price: 4110.00,
    },
    {
      quantity: 2,
      description: "BrightPaint 20kg distemper",
      price: 1330.00,
    },
    {
      quantity: 3,
      description: "BrightPaint wall putty",
      price: 850.00,
    }],
    [{
      quantity: 118,
      description: "Bright Paint 20L emultion",
      price: 41110.00,
    },
    {
      quantity: 12,
      description: "BrightPaint 20kg distemper",
      price: 11330.00,
    },
    {
      quantity: 113,
      description: "BrightPaint wall putty",
      price: 8150.00,
    }]
  ];

  totalSum:any = this.data[this.shuffleValue].reduce((init, prev) => { return Number(init) + Number(prev.quantity * prev.price) }, 0);

  ngOnInit(): void {
    this.randomInvoiceNumberGenerator();
    this.apiService.login().subscribe(async (res:any)=>{
      this.token = await res.token;
      this.entityLists();
    })
  }

  randomInvoiceNumberGenerator() {
    this.random_invoice_number = Math.floor(Math.random() * 40000).toString();
  }

  sum(data: any) {
    this.totalSum += Number(data);
  }

  Submit() {
    let yourDate = new Date()
    const formData:any = new FormData();
    formData.append('invoice_amount',this.totalSum);
    formData.append('invoice_date',yourDate.toISOString().split('T')[0]);
    formData.append('invoice_number',this.random_invoice_number);
    formData.append('invoice_type','IN');
    formData.append('buyerGST',this.buyer.company_gstin);
    formData.append('sellerGST',this.seller.company_gstin);
    formData.append('total_tax_amount',(0.18 * this.totalSum));
    this.apiService.submitInvoice(formData).subscribe((res:any)=>{
      if(res.status == 'true'){
          window.alert(res.message);
      }else{
        window.alert(res.message);
      }
    })
  };

  sellectSeller(data?:any){
    this.apiService.getCompanyDetails({cid: data.seller, token: this.token}).subscribe((res:any)=>{
      this.seller = {
        company_name:res.company.company_name,
        company_gstin:res.company.gstin,
        company_address: res.company.address,
        company_phone: res.company.company_mobile
      }
    });
  };

  sellectBuyer(data?:any){
    this.apiService.getCompanyDetails({cid: data.buyer, token: this.token}).subscribe((res:any)=>{
      this.buyer = {
        company_name:res.company.company_name,
        company_gstin:res.company.gstin,
        company_address: res.company.address,
        company_phone: res.company.company_mobile,
        company_credit_sanctioned: res.company.creditLimit,
        company_credit_available: res.company.avail_credit
      }
    });
  };

  entityLists(){
    this.apiService.getEntityList({ token: this.token}).subscribe((res:any)=>{
      this.entityList = res.companies.map((list:any)=>{return { _id: list?._id, company_name: list?.company_name}})
    })
  };

}

