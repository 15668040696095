import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  constructor(
    private http: HttpClient,
  ) { }

  login(data?: any) {
    return this.http.post('https://demo.xuriti.app/api/auth/user-login', {
      "email": "vikas@xuriti.com",
      "password": "Xuriti#10",
      "recaptcha": "test_recaptcha"
    })

  }
  submitInvoice(data: any) {
    return this.http.post('https://demo.xuriti.app/api/invoice/upload-invoice-manual', data, {
      headers: {
        "x-client-key": 'eyJhbGciOiJSUzI1NiJ9.MDNjZWZlNWEtMGMzOC00MDgzLTlhOWItM2NhMTA1YjY2NDg4.ZNFMDiKEce8m2-JxinCcvH1WvlTaIkV5UFkWab5J4SntRDDv583HzKINabFlxIluxudAvLG_YYkcPoQM3IlEpSKyNQmCbJSABi-lobKwDXoO7vx3PKB4wBgmODt6adNZc2eDayAMH-G4SlasKqp8lZFesbXgkvI0Z19coSHTkPJ34CN65H-WKgWi0qxZDjwxJg9T-TfaMFNngR-6SsfsnHr2bmbpBkqhH-3Fr8svhSMO1FubhxZ5WjaQ_9zdA-_f4T-jF99S_Zfe-cpE8-w9ho-Td2Jf9HcR4E54v9RDPw2qoEL6WgKWH0DloolYSWNdCybJ6vT2RcYFv-Jtqimipa2MJlNn3MkC6t77BnuPoHkv4-kXkRwDtL1FNjQBwsWGy6MNmlrjqZbwOCpCivCTWSK4pHWe76mAJZXFWC-4OHk8aofW-7DW-HAKsKx3YyEByvUC-TM9xPXNkoRB7UTHtZFr0TmmgGTRQwstNXzdkXTDYUNLMQttBNx_x00Q39ch',
        "x-user-id": "63ef437078b1070b14a78f03"
      }
    })
  };

  getCompanyDetails(data?: any) {
    return this.http.get('https://demo.xuriti.app/api/entity/entity/' + data.cid, {
      headers: {
        "authorization": `Bearer ${data.token}`
      }
    })
  };

  getSellerList(data?: any) {
    return this.http.get('https://demo.xuriti.app/api/entity/seller-list', {
      headers: {
        "authorization": `Bearer ${data.token}`
      }
    })
  };

  getEntityList(data?: any) {
    return this.http.get('https://demo.xuriti.app/api/entity/entities', {
      headers: {
        "authorization": `Bearer ${data.token}`
      }
    })
  };
}
